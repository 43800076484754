import React from "react"
import Header from "../components/header"
import Container from "../components/container"

export default function About() {
  return (
    <Container>
      <div style={{ color: "teal" }}>
        <Header headerText="About Gatsby" />
        <Header headerText="It's pretty cool" />
        <p>about page</p>
      </div>
    </Container>
  )
}
